<template>
    <v-alert
        dark
        class="program-card text-uppercase text-center height100 d-flex align-center justify-center lighten-2 cursor-pointer"
        outlined
        text
        :color="program.theme"
        @click="gotoJourney(program)"
        v-bind:class="{ 'darken-5': program.theme }"
    >
        <p>{{ program.journey_name }}</p>
        <small class="secondary-font" v-if="program.status === 'INACTIVE'">
            <v-icon small light> mdi-lock </v-icon>
        </small>
    </v-alert>
</template>

<script>
import commonService from '../../services/common-service';
import notificationsUtility from '../../utilities/notifications-utility';
import commonData from '../../constant/common-data';

export default {
    name: 'Program',
    props: {
        program: {
            type: [Array, Object],
            default: null,
        },

        applications: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {};
    },

    computed: {
        site() {
            return this.$store.state.common.site;
        },
    },

    methods: {
        gotoJourney(program) {
            if (
                commonData.canApplyJourneyMultipleTimes.includes(this.site) ||
                program.is_allow_for_multiple_submit
            ) {
                this.$router.push(`/${this.site}/application/create/${program.uuid}`);
            } else {
                let currentUser = this.$store.state.auth.user;
                if (
                    localStorage.getItem('log_as_user_status') &&
                    localStorage.getItem('log_as_user_status') === 'true'
                ) {
                    currentUser = JSON.parse(localStorage.getItem('log_as_user'));
                }

                let payload = {
                    user: currentUser,
                    journey: program.id,
                    applications: this.applications,
                };

                commonService
                    .post('application/check-eligibility', payload)
                    .then((response) => {
                        if (!response.data) {
                            notificationsUtility.pushNotifications([
                                {
                                    message: 'Application already submitted.',
                                    type: 'error',
                                },
                            ]);
                        } else {
                            this.$router.push(`/${this.site}/application/create/${program.uuid}`);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            }
        },
    },
};
</script>
