export default {
    entityTypes: [
        { text: 'Contact', value: 'Contact' },
        { text: 'Application', value: 'Application' },
    ],
    entityTypesProfile: [{ text: 'Contact', value: 'Contact' }],
    crmDropdownList: [
        { text: 'Programs', value: 'programs' },
        { text: 'Countries', value: 'countries' },
        { text: 'Research Activities', value: 'research-activities' },
    ],
    inputTypes: [
        { value: 'TEXT_FIELD', text: 'Text Field', crm_required: true, validation_allow: true },
        { value: 'TEXT_AREA', text: 'Text Area', crm_required: true, validation_allow: true },
        { value: 'DROP_DOWN', text: 'Drop Down', crm_required: true, validation_allow: true },
        { value: 'CHECK_BOX', text: 'Checkbox', crm_required: true, validation_allow: true },
        { value: 'RADIO', text: 'Radio', crm_required: true, validation_allow: true },
        { value: 'DATE', text: 'Date', crm_required: true, validation_allow: true },
        { value: 'TIME', text: 'Time', crm_required: true, validation_allow: true },
        { value: 'FILE_UPLOAD', text: 'File Upload', crm_required: true, validation_allow: true },
        { value: 'DOCUMENT', text: 'Document', crm_required: false, validation_allow: true },
        { value: 'TEXT_EDITOR', text: 'Text Editor', crm_required: false, validation_allow: true },
        { value: 'BUTTON', text: 'Button', crm_required: false, validation_allow: true },
        // { value: 'DATABASE', text: 'Database' },// For Future purpose
        { value: 'DATABASE', text: 'University', crm_required: true, validation_allow: true }, //
        { value: 'COMPETITOR', text: 'Competitor', crm_required: true, validation_allow: true }, //
        {
            value: 'CRM_DROP_DOWN',
            text: 'CRM Dropdown',
            crm_required: true,
            validation_allow: true,
        },
        { value: 'LABEL', text: 'Label', crm_required: false, validation_allow: true },
        { value: 'SPACER', text: 'Spacer', crm_required: false, validation_allow: true },
        { value: 'PAYMENT', text: 'Payment', crm_required: false, validation_allow: true },
        {
            value: 'APPLICATION_STATUS',
            text: 'Course Status',
            crm_required: false,
            validation_allow: true,
        },
        { value: 'COURSES', text: 'Courses', crm_required: false, validation_allow: true },
    ],
    textFieldTypes: [
        { value: 'number', text: 'Number' },
        { value: 'text', text: 'Text' },
        { value: 'email', text: 'Email' },
        { value: 'email', text: 'Email' },
        { value: 'alphanumeric_no_space', text: 'Alphanumeric - No Spaces' },
        { value: 'alphanumeric_with_space', text: 'Alphanumeric - With Spaces' },
    ],
    dateFieldTypes: [{ value: 'date', text: 'Date' }],
    dateActions: [
        { value: 'futureDate', text: 'Future Date' },
        { value: 'pastDate', text: 'Past Date' },
        { value: 'minDate', text: 'Min Date' },
        { value: 'maxDate', text: 'Max Date' },
        { value: 'dateBetween', text: 'Date Between' },
    ],
    itemsLength: [
        { value: 'maxLength', text: 'Max Length' },
        { value: 'minLength', text: 'Min Length' },
    ],
    numberLogic: [
        { value: 'greaterThan', text: 'Greater than' },
        { value: 'greaterThanEqual', text: 'Greater than or equal' },
        { value: 'lessThan', text: 'Less than' },
    ],
    visibleActions: [
        { value: 'show', text: 'Show' },
        { value: 'hide', text: 'Hide' },
    ],
    enableActions: [
        { value: 'enable', text: 'Enable' },
        { value: 'disable', text: 'Disable' },
    ],
    operators: [
        { value: 'AND', text: 'AND' },
        { value: 'OR', text: 'OR' },
    ],
    databaseData: ['universities'],
    themes: [
        { value: '#005d9f', text: 'Theme Blue', color: '#005d9f' },
        { value: '#df7a0e', text: 'Theme Orange', color: '#df7a0e' },
        { value: '#30515f', text: 'Theme Gray', color: '#30515f' },
    ],
    gridSizes: [
        { value: 12, text: 'Full Width' },
        { value: 11, text: 'Col-11' },
        { value: 10, text: 'Col-10' },
        { value: 9, text: 'Col-9' },
        { value: 8, text: 'Col-8' },
        { value: 7, text: 'Col-7' },
        { value: 6, text: 'Col-6' },
        { value: 5, text: 'Col-5' },
        { value: 4, text: 'Col-4' },
        { value: 3, text: 'Col-3' },
        { value: 2, text: 'Col-2' },
        { value: 1, text: 'Col-1' },
    ],
    inputTypesProfile: [
        { value: 'TEXT_FIELD', text: 'Text Field', crm_required: true, validation_allow: true },
        { value: 'TEXT_AREA', text: 'Text Area', crm_required: true, validation_allow: true },
        { value: 'DROP_DOWN', text: 'Drop Down', crm_required: true, validation_allow: true },
        { value: 'CHECK_BOX', text: 'Checkbox', crm_required: true, validation_allow: true },
        { value: 'RADIO', text: 'Radio', crm_required: true, validation_allow: true },
        { value: 'DATE', text: 'Date', crm_required: true, validation_allow: true },
        { value: 'TIME', text: 'Time', crm_required: true, validation_allow: true },
        { value: 'TEXT_EDITOR', text: 'Text Editor', crm_required: false, validation_allow: true },
        { value: 'BUTTON', text: 'Button', crm_required: false, validation_allow: true },
        {
            value: 'CRM_DROP_DOWN',
            text: 'CRM Dropdown',
            crm_required: true,
            validation_allow: true,
        },
        { value: 'LABEL', text: 'Label', crm_required: false, validation_allow: true },
        { value: 'SPACER', text: 'Spacer', crm_required: false, validation_allow: true },
    ],
    status: [
        { value: 'ACTIVE', text: 'ACTIVE' },
        { value: 'INACTIVE', text: 'INACTIVE' },
    ],
    fileTypes: ['pdf', 'docx', 'jpg'],
    documentTypes: [
        { value: 'None', text: 'None' },
        { value: 'Contract', text: 'Contract' },
        { value: 'NDA', text: 'NDA' },
        { value: 'EULA', text: 'EULA' },
        { value: 'License Agreement', text: 'License Agreement' },
        { value: 'Transcript', text: 'Transcript' },
        { value: 'CV', text: 'CV' },
        { value: 'Reference Letter 1', text: 'Reference Letter 1' },
        { value: 'Reference Letter 2', text: 'Reference Letter 2' },
        { value: 'Passport', text: 'Passport' },
        { value: 'Proof English', text: 'Proof English' },
        { value: 'Statement of Purpose Op1', text: 'Statement of Purpose Op1' },
        { value: 'Statement of Purpose Op2', text: 'Statement of Purpose Op2' },
        { value: 'Statement of Purpose Op3', text: 'Statement of Purpose Op3' },
        { value: 'Statement of Purpose All', text: 'Statement of Purpose All' },
        { value: 'Unified', text: 'Unified' },
        { value: 'RP Paper', text: 'RP Paper' },
    ],
    canApplyJourneyMultipleTimes: ['summer_forum', 'summer_forum_attendance'],
};
