<template>
    <v-dialog v-model="openEditJourneyDialog" persistent content-class="small-dialog">
        <v-card>
            <v-card-title class="d-flex justify-space-between align-center secondary-font">
                <p class="h6 primary--text font-weight-bold mb-0">{{ dialogTitle }}</p>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" text icon color="primary" @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card-text class="secondary-font pb-0">
                    <v-row>
                        <v-col cols="12">
                            <p class="caption mb-0">Journey name</p>
                            <v-text-field
                                v-model="journey.journey_name"
                                :rules="[(v) => !!v || 'Journey name is required']"
                                dense
                                outlined
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Period</p>
                            <v-select
                                :items="academicPeriods"
                                v-model="journey.academic_year_id"
                                :rules="[(v) => !!v || 'Period is required']"
                                dense
                                outlined
                                @change="getProgramNames"
                                item-text="period"
                                item-value="uuid"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Program Type</p>
                            <v-select
                                :items="journeyTypes"
                                v-model="journey.journey_type"
                                @change="getProgramNames"
                                :rules="[(v) => !!v || 'Program Type is required']"
                                dense
                                outlined
                                item-text="journey_type"
                                item-value="uuid"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" v-if="journeyPrograms.length > 0">
                            <p class="caption mb-0">Program Name</p>
                            <v-select
                                :items="journeyPrograms"
                                v-model="journey.program_id"
                                dense
                                outlined
                                item-text="name"
                                item-value="id"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Entity Type</p>
                            <v-select
                                :items="entityTypes"
                                v-model="journey.entity_type_id"
                                :rules="[(v) => !!v || 'Entity Type is required']"
                                dense
                                outlined
                                item-text="name"
                                item-value="uuid"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <p class="caption mb-0">Theme</p>
                            <v-select
                                :items="themeTypes"
                                v-model="journey.theme"
                                :rules="[(v) => !!v || 'Entity Type is required']"
                                dense
                                outlined
                                item-text="text"
                                item-value="color"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" class="pb-0 mb-0">
                            <p class="caption mb-0">Sequence Number</p>
                            <v-text-field
                                v-model="journey.order"
                                :rules="[
                                    (v) => !!v || 'Sequence number is required',
                                    (v) =>
                                        v >= 1 ||
                                        'Sequence number should be greater than or equal to 1',
                                    (v) =>
                                        v <= journeyCount ||
                                        `Sequence number should be between 1 and ${journeyCount}`,
                                ]"
                                dense
                                outlined
                                type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" class="pt-0 mt-0">
                            <v-checkbox
                                label="Import Journey"
                                class="pa-0 mb-3"
                                hide-details
                                v-model="copiedJourneyArea"
                            ></v-checkbox>
                            <div v-if="copiedJourneyArea">
                                <v-textarea
                                    v-model="copiedJourney"
                                    dense
                                    outlined
                                    :rules="[(v) => !!v || 'Journey json is required']"
                                >
                                </v-textarea>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="d-flex">
                        <v-switch
                            v-if="isUserAdmin"
                            label="Admin Only"
                            v-model="isAdmin"
                            flat
                            hide-details
                        ></v-switch>
                        <v-switch
                            :disabled="!isAdmin"
                            class="ml-2"
                            v-if="isUserAdmin"
                            label="Allow Only for already applied"
                            v-model="journey.is_allow_for_only_applied"
                            flat
                            hide-details
                        ></v-switch>
                    </div>
                    <v-row>
                        <v-col cols="12">
                            <v-switch
                                v-if="isResearchParticipation"
                                label="Allow multiple submissions"
                                v-model="journey.is_allow_for_multiple_submit"
                                flat
                                hide-details
                            ></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary mr-2" @click="save()" elevation="0"> Save</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '../../store/index';
import commonData from '../../constant/common-data';
import JourneyService from '../../services/journey-service';
import notificationsUtility from '../../utilities/notifications-utility';
export default {
    name: 'EditJourneyPopup',
    props: {
        journeyData: Object,
        journeyCount: Number,
    },
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            isAdmin: true,
            journey: {
                journey_type: null,
                journey_name: '',
                academic_year_id: null,
                program_id: null,
                entity_type_id: null,
                status: 'INACTIVE',
                theme: null,
                order: null,
                is_allow_for_only_applied: false,
                is_allow_for_multiple_submit: false,
            },
            copiedJourney: '',
            copiedJourneyArea: false,
            dialogTitle: 'Create Journey',
            isEdited: false,
            journeyTypes: [],
            journeyPrograms: [],
            themeTypes: commonData.themes,
            academicPeriods: [],
            valid: true,
            isResearchParticipation: false,
        };
    },
    methods: {
        close() {
            this.$emit('close-edit-journey-dialog');
            this.$nextTick(() => {
                Object.assign(this.$data, this.$options.data());
            });
        },
        checkJourneyJson() {
            try {
                JSON.parse(this.copiedJourney);
            } catch (e) {
                notificationsUtility.pushNotifications([
                    {
                        message: 'Not a valid json',
                        type: 'error',
                    },
                ]);
                return false;
            }
            return true;
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.copiedJourneyArea) {
                    let check = this.checkJourneyJson();
                    if (check === true) {
                        this.journey.form_data = JSON.parse(this.copiedJourney);
                        this.sendJourneyToSave();
                    }
                } else {
                    this.sendJourneyToSave();
                }
            }
        },
        sendJourneyToSave() {
            if (this.isEdited) {
                JourneyService.updateJourney(this.journey)
                    .then((response) => {
                        if (response.data.data) {
                            notificationsUtility.pushNotifications([
                                { message: 'Journey updated successfully ', type: 'success' },
                            ]);
                            this.$store.commit(
                                'journey/setDefaultEntity',
                                response.data.data.entity_type
                            );
                            if (this.copiedJourneyArea) {
                                location.reload();
                            }
                            this.$emit('close-edit-journey-dialog', response.data.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response.data.status === 0) {
                            notificationsUtility.pushNotifications([
                                {
                                    message: error.response.data.message,
                                    type: 'error',
                                },
                            ]);
                        } else {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            }
        },
        getJourneyTypes() {
            JourneyService.getJourneyTypes()
                .then((response) => {
                    this.journeyTypes = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        // TODO IMPLEMENT
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
        getAcademicYears() {
            JourneyService.getAcademicYears()
                .then((response) => {
                    this.academicPeriods = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status !== 503) {
                        // TODO IMPLEMENT
                        notificationsUtility.pushNotifications([
                            {
                                message: 'Something went wrong. Please try contact server admin',
                                type: 'error',
                            },
                        ]);
                    }
                });
        },
        setInitials() {
            if (this.journeyData != null) {
                this.isEdited = true;
                this.dialogTitle = 'Update Journey';
                this.journey.academic_year_id = this.journeyData.academic_year_id;
                this.journey.entity_type_id = this.journeyData.entity_type_id;
                this.journey.status = this.journeyData.status;
                this.journey.theme = this.journeyData.theme;
                this.journey.order = this.journeyData.order;
                this.journey.journey_name = this.journeyData.journey_name;
                this.journey.uuid = this.journeyData.uuid;
                this.isAdmin = this.journeyData.status === 'ACTIVE' ? false : true;
                this.getProgramNames();
                this.journey.program_id = this.journeyData.program_id;
                this.journey.is_allow_for_only_applied = this.journeyData.is_allow_for_only_applied;
                this.journey.is_allow_for_multiple_submit =
                    this.journeyData.is_allow_for_multiple_submit;
                this.journey.journey_type = this.journeyData.journey_type_id;

                this.isResearchParticipation =
                    this.journeyData.entity_type == 'ResearchParticipation' ? true : false;
            }
        },
        getProgramNames() {
            if (this.journey.academic_year_id !== null && this.journey.journey_type !== null) {
                this.journey.program_id = null;
                const params = {
                    academicYear: this.journey.academic_year_id,
                    journeyTypeId: this.journey.journey_type,
                };

                JourneyService.getPrograms(params)
                    .then((response) => {
                        this.journeyPrograms = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response.status !== 503) {
                            notificationsUtility.pushNotifications([
                                {
                                    message:
                                        'Something went wrong. Please try contact server admin',
                                    type: 'error',
                                },
                            ]);
                        }
                    });
            }
        },
        checkIfResearchParticipation() {
            let selectedEntityType = this.entityTypes.filter(
                (entityType) => entityType.uuid == this.journey.entity_type_id
            );

            return selectedEntityType &&
                selectedEntityType.length &&
                selectedEntityType[0].name == 'ResearchParticipation'
                ? true
                : false;
        },
    },
    mounted() {
        this.getAcademicYears();
        this.getJourneyTypes();

        setTimeout(() => {
            this.setInitials();
        }, 800);
    },
    computed: {
        openEditJourneyDialog: () => {
            return store.state.journey.openEditJourneyDialog;
        },
        entityTypes: () => {
            return store.state.journey.entityTypes;
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        isUserAdmin() {
            if (this.currentUser && this.currentUser.role) {
                return ['ADMIN'].includes(this.currentUser.role[0].name);
            }

            return false;
        },
        sites() {
            return this.$store.state.common.sites;
        },
        site() {
            return this.$store.state.common.site;
        },
    },
    watch: {
        isAdmin() {
            this.journey.status = this.isAdmin === true ? 'INACTIVE' : 'ACTIVE';
            this.journey.is_allow_for_only_applied =
                this.isAdmin === false ? false : this.journey.is_allow_for_only_applied;
        },
        journey: {
            handler() {
                this.isResearchParticipation = this.checkIfResearchParticipation();

                if (!this.isResearchParticipation) {
                    this.journey.is_allow_for_multiple_submit = false;
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped></style>
